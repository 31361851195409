.outer {
  width: 100%;
  align-items: center;
  text-align: center;
}

/* Search Bar */

.searchrow {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
}

.searchrow span {
  flex-grow: 1;
}

.searchbar {
  flex-grow: 2;
  display: flex;
  border: 3px solid black;
  padding: 10px;
  border-radius: 8px;
}

.searchbar input {
  border: none;
  width: 100%;
  outline: none;
  font-size: larger;
}

.searchbar button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Meal list */
.mealContainer {
  width: 100%;
  padding-top: 10px;
  text-decoration: none;
}

.mealcard {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;

  flex-direction: row;
  display: flex;
  justify-content: space-between;

  width: 70%;
  color: black;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.mealcard span {
  flex: 1;
}

.about-meal {
  padding-left: 2.5%;
  align-items: center;
  text-align: start;
}

.about-meal h1 {
  margin-bottom: 0;
  padding-left: 2.5%;
}

.about-meal p {
  font-size: smaller;
  margin-top: 0;
}

/* Nutrition breakdown */

.nutritionbreakdown {
  height: 100px;
  flex-direction: row;
  display: flex;
}

.nutritionbreakdown-numerals-container {
  flex-direction: column;
  display: flex;
}

.nutrition-numerals {
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
.nutrition-numerals span {
  width: 1rem;
  flex: 1;
}

.nomealsfound {
  padding-top: 40px;
  font-size: xx-large;
}
