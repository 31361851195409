.outer {
    width: 50%;
    margin: auto;
}

.form {
    text-align: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    display: flex;
    padding: 10% 10% 10% 10%;
    border: 1px solid black;
    border-radius: 5px;
    width: min-content;
}

.spacer {
    width: 2%;
    margin-left: 2%;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    align-content: space-between;
}

.row button {
  font: inherit;
  background-color: transparent;
  border: 1px solid #1950f7;
  color: #1950f7;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.row a:hover {
    color: white;
}
  
.row button:hover {
    background-color: #1950f7;
    color: white;
}

.login-form-mobile {
    width: 75%;
}

.login-form-web {
    width: 5%;
}