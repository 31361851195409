.nutrition-chart-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.nutrition-chart {
  flex-grow: 1;
  width: min-content;
  margin-left: auto;
  margin-right: auto;
}

.calories {
  font-size: medium;
}

.calories-description {
  font-size: xx-small;
}

.macros {
  font-size: smaller;
}

.macros-description {
  font-size: x-small;
}

.centered-values {
  margin-top: -65%;
}

.centered-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  width: inherit;
}

.centered-calories {
  font-size: x-large;
}

.centered-calories-description {
  font-size: medium;
}

.centered-macros {
  font-size: large;
}

.centered-macros-description {
  font-size: small;
}

.centered-values span {
  flex-grow: 1;
}
