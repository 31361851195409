body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.floating-action-button {
  position: absolute;
  width: 65px;
  height: 65px;
  background: #2cb3f0;
  bottom: 0;
  border-radius: 50%;
  right: 0;
  margin: auto;
  margin-right: 1%;
  margin-bottom: 1%;
  color: white;
  line-height: 65px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  text-decoration: none;
}
.floating-action-button:hover {
  background: grey;
}

.floating-navigate-to-top-button {
  position: absolute;
  width: 45px;
  height: 45px;
  background: #2cb3f0;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  margin: auto;
  margin-left: 10%;
  margin-bottom: 1%;
  color: white;
  line-height: 45px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  text-decoration: none;
}

.floating-action-button:hover {
  background: grey;
}
