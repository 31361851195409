.outer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.info-panel {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.nutrition-breakdown {
  flex-grow: 1;
}

.add-meal {
}
