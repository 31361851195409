.outer {
  width: 100%;
  align-items: center;
  text-align: center;
}

.date {
  margin-left: 40%;
  margin-right: 40%;
  font-size: larger;
  margin-top: 15px;
  margin-bottom: 15px;
}

.nutrition {
  display: flex;
  flex-direction: row;
}

.breakdown {
  width: 40%;
  height: 500px;
}

.add-new {
  font-size: xx-large;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.meal {
  box-shadow: 1px black;
  margin-left: 40px;
  width: 100px;
  height: 220px;
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
}

.meal h4 {
  margin-bottom: 0px;
}

.amount {
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  justify-content: space-between;
}

.change-amount button {
  border: none;
  background-color: transparent;
}

.remove {
  color: red;
}

.meals {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.exersize {
}

.exersizes {
}
