body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.floating-action-button {
  position: absolute;
  width: 65px;
  height: 65px;
  background: #2cb3f0;
  bottom: 0;
  border-radius: 50%;
  right: 0;
  margin: auto;
  margin-right: 1%;
  margin-bottom: 1%;
  color: white;
  line-height: 65px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  text-decoration: none;
}
.floating-action-button:hover {
  background: grey;
}

.floating-navigate-to-top-button {
  position: absolute;
  width: 45px;
  height: 45px;
  background: #2cb3f0;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  margin: auto;
  margin-left: 10%;
  margin-bottom: 1%;
  color: white;
  line-height: 45px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  text-decoration: none;
}

.floating-action-button:hover {
  background: grey;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
  height: 5rem;
  background-color: #19cff7;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1%;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin: 0 1rem;
}

.header a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.header button {
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.header a:hover {
  color: #1950f7;
}

.header button:hover {
  background-color: #1950f7;
  color: #19cff7;
}

.Background_background__21ByG {
    width: 100%;
    height: 100%;
    /* color: black;
    background-color: green; */
    position: absolute;
    z-index: -1;
}
.Login_outer__3BYOF {
    width: 50%;
    margin: auto;
}

.Login_form__4iqh5 {
    text-align: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    display: flex;
    padding: 10% 10% 10% 10%;
    border: 1px solid black;
    border-radius: 5px;
    width: -webkit-min-content;
    width: min-content;
}

.Login_spacer__I2C2E {
    width: 2%;
    margin-left: 2%;
}

.Login_row__2SbQx {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    align-content: space-between;
}

.Login_row__2SbQx button {
  font: inherit;
  background-color: transparent;
  border: 1px solid #1950f7;
  color: #1950f7;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.Login_row__2SbQx a:hover {
    color: white;
}
  
.Login_row__2SbQx button:hover {
    background-color: #1950f7;
    color: white;
}

.Login_login-form-mobile__17X-x {
    width: 75%;
}

.Login_login-form-web__2Z_Cf {
    width: 5%;
}
.Home_outer__1fFvF {
    
}

.Home_info-panel__3K5tC {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AddWorkout_outer__1AlKv {
    
}

.AddWorkout_info-panel__3358T {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.EditWorkout_outer__1F3xE {
    
}

.EditWorkout_info-panel__1EaLN {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Days_outer__SrVt7 {
  width: 100%;
  margin-top: 2%;
}

.Days_daysContainer__3jnvX {
  margin-top: 2%;
  flex-direction: row;
  display: flex;
  width: 100%;
}

.Days_dayContainerSpacer__psVYZ {
  flex-grow: 1;
}
.Days_days__3OiN9 {
  width: 50%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(7, minmax(10%, 100px));
  grid-template-rows: repeat(auto, 50px);
  align-items: center;
  justify-content: space-between;
}

.Days_day__1g6En {
  text-decoration: none;
}

.Days_daycard__2I8Y5 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  height: 120px;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
}

.Days_monthRow__XxzNQ {
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: row;
  display: flex;
}

.Days_monthText__1C3A2 {
  flex-grow: 1;
}

.Days_monthSelector__3nYwp {
  /* flex-grow: 1; */
  width: -webkit-min-content;
  width: min-content;
}

.Loading_loading__hAt1O {
  font-size: large;
}

.NutritionChart_nutrition-chart-container__1bzct {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.NutritionChart_nutrition-chart__1Mp3h {
  flex-grow: 1;
  width: -webkit-min-content;
  width: min-content;
  margin-left: auto;
  margin-right: auto;
}

.NutritionChart_calories__PKGTj {
  font-size: medium;
}

.NutritionChart_calories-description__1vqO6 {
  font-size: xx-small;
}

.NutritionChart_macros__1HdM3 {
  font-size: smaller;
}

.NutritionChart_macros-description__2gHDm {
  font-size: x-small;
}

.NutritionChart_centered-values__3Bk4s {
  margin-top: -65%;
}

.NutritionChart_centered-info-container__21wld {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  width: inherit;
}

.NutritionChart_centered-calories__1-Em9 {
  font-size: x-large;
}

.NutritionChart_centered-calories-description__1mxW3 {
  font-size: medium;
}

.NutritionChart_centered-macros__2WIR4 {
  font-size: large;
}

.NutritionChart_centered-macros-description__3l0dj {
  font-size: small;
}

.NutritionChart_centered-values__3Bk4s span {
  flex-grow: 1;
}

.Day_outer__2P3v9 {
  width: 100%;
  align-items: center;
  text-align: center;
}

.Day_date__AAD-j {
  margin-left: 40%;
  margin-right: 40%;
  font-size: larger;
  margin-top: 15px;
  margin-bottom: 15px;
}

.Day_nutrition__Fljxi {
  display: flex;
  flex-direction: row;
}

.Day_breakdown__c8AlY {
  width: 40%;
  height: 500px;
}

.Day_add-new__2amSh {
  font-size: xx-large;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.Day_meal__19Shj {
  box-shadow: 1px black;
  margin-left: 40px;
  width: 100px;
  height: 220px;
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
}

.Day_meal__19Shj h4 {
  margin-bottom: 0px;
}

.Day_amount__FJFML {
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  justify-content: space-between;
}

.Day_change-amount__nT6Rw button {
  border: none;
  background-color: transparent;
}

.Day_remove__3fSw1 {
  color: red;
}

.Day_meals__2OZzu {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.Day_exersize__2qXTC {
}

.Day_exersizes__13h0I {
}

.NotFound_outer__1gwnN {
    
}

.NotFound_info-panel__59KmT {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Meals_outer__hF5Ha {
  width: 100%;
  align-items: center;
  text-align: center;
}

/* Search Bar */

.Meals_searchrow__hPq-8 {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
}

.Meals_searchrow__hPq-8 span {
  flex-grow: 1;
}

.Meals_searchbar__3VP8H {
  flex-grow: 2;
  display: flex;
  border: 3px solid black;
  padding: 10px;
  border-radius: 8px;
}

.Meals_searchbar__3VP8H input {
  border: none;
  width: 100%;
  outline: none;
  font-size: larger;
}

.Meals_searchbar__3VP8H button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Meal list */
.Meals_mealContainer__3C2_N {
  width: 100%;
  padding-top: 10px;
  text-decoration: none;
}

.Meals_mealcard__1MrnB {
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;

  flex-direction: row;
  display: flex;
  justify-content: space-between;

  width: 70%;
  color: black;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.Meals_mealcard__1MrnB span {
  flex: 1 1;
}

.Meals_about-meal__35iG4 {
  padding-left: 2.5%;
  align-items: center;
  text-align: start;
}

.Meals_about-meal__35iG4 h1 {
  margin-bottom: 0;
  padding-left: 2.5%;
}

.Meals_about-meal__35iG4 p {
  font-size: smaller;
  margin-top: 0;
}

/* Nutrition breakdown */

.Meals_nutritionbreakdown__2swBB {
  height: 100px;
  flex-direction: row;
  display: flex;
}

.Meals_nutritionbreakdown-numerals-container__2EtXj {
  flex-direction: column;
  display: flex;
}

.Meals_nutrition-numerals__26Xpr {
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
.Meals_nutrition-numerals__26Xpr span {
  width: 1rem;
  flex: 1 1;
}

.Meals_nomealsfound__2dwfk {
  padding-top: 40px;
  font-size: xx-large;
}

.Meal_outer__197ms {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.Meal_info-panel__3xS-o {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.Meal_nutrition-breakdown__2wz0r {
  flex-grow: 1;
}

.Meal_add-meal__3n2Pr {
}

.AddMeal_outer__3URJy {
  width: 100%;
}

.AddMeal_form-input__3lNnZ {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 25%;
  margin-top: 2.5%;
  text-align: start;

  /* border: 2px solid; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2%;
}

.AddMeal_text-input__2nbxd {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.AddMeal_text-input__2nbxd span {
  width: 20px;
  flex: 1 1;
}

.AddMeal_submit-row__3EEpq {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddMeal_submit-row__3EEpq span {
  flex-grow: 1;
  width: 20px;
}

.AddMeal_submit-row__3EEpq button {
}

