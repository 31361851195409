.outer {
  width: 100%;
  margin-top: 2%;
}

.daysContainer {
  margin-top: 2%;
  flex-direction: row;
  display: flex;
  width: 100%;
}

.dayContainerSpacer {
  flex-grow: 1;
}
.days {
  width: 50%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(7, minmax(10%, 100px));
  grid-template-rows: repeat(auto, 50px);
  align-items: center;
  justify-content: space-between;
}

.day {
  text-decoration: none;
}

.daycard {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  height: 120px;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
}

.monthRow {
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: row;
  display: flex;
}

.monthText {
  flex-grow: 1;
}

.monthSelector {
  /* flex-grow: 1; */
  width: min-content;
}
