.outer {
  width: 100%;
}

.form-input {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 25%;
  margin-top: 2.5%;
  text-align: start;

  /* border: 2px solid; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2%;
}

.text-input {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.text-input span {
  width: 20px;
  flex: 1;
}

.submit-row {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.submit-row span {
  flex-grow: 1;
  width: 20px;
}

.submit-row button {
}
